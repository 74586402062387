
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment-timezone';

import FinanceNav from './../components/FinanceNav';
import { isOnlyQaffOrCsr, isIrff } from './../utils/roles';
import { PAGES, hasAcces } from './../utils/userAccess';

import styles from './finances.styl';
import stylesWriter from './writer-page.styl';

import {
  Table,
  writerWithdrawalTableStorePack,
  settingsActions,
  // utils,
  pluginConfigs,
  mainHubActions,
  profileStorePack,
  controls,
} from '@verdaccio/crminfo';

const STATUS = [
  ['completed', 'completed'],
  ['canceled', 'canceled'],
  ['execution', 'execution']
];

const PAYMENTS = [
  ['PayPal', 'PAYPAL'],
  ['Payoneer', 'Payoneer'],
  ['Scrill', 'SKRILL'],
  ['WireTransfer', 'WireTransfer'],
  ['Wise', 'Wise']
];

class WriterWithdrawalPage extends Component {
  componentWillMount() {
    const { profile, history } = this.props;

    if (!hasAcces(PAGES.FINANCES, profile.roles)) {
      history.replace('/tickets');
      return;
    }
  }


  renderTotal(sum) {
    const newSum = (sum / 100);
    if (newSum < 0) {
      return `($${Math.abs(newSum.toFixed(2))})`
    }
    if (newSum >= 0) {
      return `$${newSum.toFixed(2)}`
    }
  }

  render() {
    const { setSorting, profile, updateStatus, writerWithdrawalStatistic, setFilter } = this.props;
    const isQaffOrCsr = isOnlyQaffOrCsr(profile);

    const tableConfig = {
      fields: [
        {
          name: 'Created at',
          formatter: item => moment(item.created_at).format('MM/DD/YY HH:mm A'),
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
          dateRangeFilterConfig: {
            valueFrom: this.props.filter.created_at_gte,
            valueTo: this.props.filter.created_at_lte,
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('created_at_gte', valueFrom)
              this.props.setFilter('created_at_lte', valueTo)
            }
          },
          width: 1.6,
        },
        {
          name: 'Request before',
          formatter: item => item.payload.expected_on ? moment(item.payload.expected_on).utc().format('MM/DD/YY') : '',
          width: 1.4,
          dateRangeFilterConfig: {
            valueFrom: this.props.filter.expected_on_gte,
            valueTo: this.props.filter.expected_on_lte,
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('expected_on_gte', valueFrom)
              this.props.setFilter('expected_on_lte', valueTo)
            }
          },
          sortingConfig: {
            name: 'payload.expected_on',
            onChange: isAsc => setSorting('payload.expected_on', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'Payment due date',
          formatter: item => item.payload.due_date ? moment(item.payload.due_date).utc().format('MM/DD/YY') : '',
          width: 1.2,
          classnames: ['centered'],
        },
        {
          name: 'Payment method',
          formatter: item => item.payload.payment_method,
          width: 1.5,
          filterConfig: {
            value: this.props.filter.payment_method,
            options: PAYMENTS,
            onChange: value => setFilter('payment_method', value),
          },
          classnames: ['centered'],
        },
        {
          name: 'Payment method ID',
          formatter: item => item.payload.payment_details,
          width: 2,
        },
        {
          name: 'Amount',
          formatter: item => `$${(item.payload.amount / 100).toFixed(2)}`,
          sortingConfig: {
            name: 'amount',
            onChange: isAsc => setSorting('amount', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'Fee',
          formatter: item => `$${(item.payload.fee / 100).toFixed(2)}`,
          classnames: ['centered'],
        },
        {
          name: 'Total',
          formatter: item => `$${((item.payload.amount - item.payload.fee) / 100).toFixed(2)}`,
          classnames: ['centered'],
        },
        {
          name: 'Freelancer ID',
          formatter: item =>item.recipient_info.sg_id,
          onClick: item => {
            if (isQaffOrCsr) {
              return false;
            }
            this.props.pluginSetConfig(
              pluginConfigs.WriterPlugin({ writerId: item.recipient_info._id })
            );
          },
          width: 1.8,
          classnames: ['centered'],
        },
        // {
        //   name: 'Writer ID',
        //   formatter: item => {
        //     if (isQaffOrCsr) {
        //       return getHiddenWriterLabel(item.recipient_info.sg_id);
        //     }
        //     return item.recipient_info.name || item.recipient_info.email;
        //   },
        //   onClick: item => {
        //     if (isQaffOrCsr) {
        //       return false;
        //     }
        //     this.props.pluginSetConfig(
        //       pluginConfigs.WriterPlugin({ writerId: item.recipient_info._id })
        //     );
        //   },
        //   width: 1.8,
        // },
        {
          name: 'Status',
          formatter: (item) => {
            return (
              <controls.WithdrawalDropdown
                status={item.status}
                onChange={(writerWithdrawaRow) => updateStatus(writerWithdrawaRow)}
                withdrawalId={item.id}
              />
            )
          },
          filterConfig: {
            value: this.props.filter.status,
            options: STATUS,
            onChange: value => setFilter('status', value),
          },
          classnames: ['centered'],
          ddRight: true,
        },
      ]
    };

    const pending = writerWithdrawalStatistic && writerWithdrawalStatistic.pending;
    const available = writerWithdrawalStatistic && writerWithdrawalStatistic.available;
    const execution = writerWithdrawalStatistic && writerWithdrawalStatistic.execution;

    return (
      <div>
        <div className={styles.filter}>
          <FinanceNav />
          <div className={styles.header_wrapper}>
            {writerWithdrawalStatistic &&
              <ul className={styles.statistic}>
                <li>
                  <b className={styles.statistic_title}>Pending:</b>
                  <div className={stylesWriter.tooltip} >
                    <a className={stylesWriter.tooltip_trigger}>
                      {this.renderTotal(pending.total || 0)}
                    </a>
                    <div className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title_withdrawal)}>
                      <b>PayPal:</b> {this.renderTotal(pending.PAYPAL || 0)}<br />
                      <b>Payoneer:</b> {this.renderTotal(pending.Payoneer || 0)}<br />
                      <b>Skrill:</b> {this.renderTotal(pending.SKRILL || 0)}<br />
                      <b>WireTransfer:</b> {this.renderTotal(pending.WireTransfer || 0)}<br />
                      <b>Other:</b> {this.renderTotal(pending.other || 0)}<br />
                    </div>
                  </div >
                </li>

                <li>
                  <b className={styles.statistic_title}>Available:</b>
                  <div className={stylesWriter.tooltip} >
                    <a className={stylesWriter.tooltip_trigger}>
                      {this.renderTotal(available.total || 0)}
                    </a>
                    <div className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title_withdrawal)}>
                      <b>PayPal:</b> {this.renderTotal(available.PAYPAL || 0)}<br />
                      <b>Payoneer:</b> {this.renderTotal(available.Payoneer || 0)}<br />
                      <b>Skrill:</b> {this.renderTotal(available.SKRILL || 0)}<br />
                      <b>WireTransfer:</b> {this.renderTotal(available.WireTransfer || 0)}<br />
                      <b>Other:</b> {this.renderTotal(available.other || 0)}<br />
                    </div>
                  </div >
                </li>

                <li>
                  <b className={styles.statistic_title}>Withdrawals:</b>
                  <div className={stylesWriter.tooltip} >
                    <a className={stylesWriter.tooltip_trigger}>
                      {this.renderTotal(execution.total || 0)}
                    </a>
                    <div className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title_withdrawal)}>
                      <b>PayPal:</b> {this.renderTotal(execution.PAYPAL || 0)}<br />
                      <b>Payoneer:</b> {this.renderTotal(execution.Payoneer || 0)}<br />
                      <b>Skrill:</b> {this.renderTotal(execution.SKRILL || 0)}<br />
                      <b>WireTransfer:</b> {this.renderTotal(execution.WireTransfer || 0)}<br />
                      <b>Other:</b> {this.renderTotal(execution.other || 0)}<br />
                    </div>
                  </div >
                </li>
              </ul>
            }
            <controls.ExportCSV filter={this.props.filter} sorting={this.props.sorting} dataType='isWithdrawal' />
          </div>
        </div>
        <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="160px" mainLoader />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const profile = profileEntity && profileEntity.data;

  return {
    loading: state.writerWithdrawal && state.writerWithdrawal.loading,
    loadingNext: state.writerWithdrawal && state.writerWithdrawal.loadingNext,
    list: (state.writerWithdrawal && state.writerWithdrawal.data && state.writerWithdrawal.data.list) || [],
    total: state.writerWithdrawal && state.writerWithdrawal.data && state.writerWithdrawal.data.total,
    page: state.writerWithdrawal && state.writerWithdrawal.data && state.writerWithdrawal.data.page,
    error: state.writerWithdrawal && state.writerWithdrawal.error,
    filter: state.writerWithdrawal && state.writerWithdrawal.filter,
    sorting: state.writerWithdrawal && state.writerWithdrawal.sorting,
    writerWithdrawalStatistic: state.writerWithdrawal && state.writerWithdrawal.data && state.writerWithdrawal.data.finance_stat,
    profile,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(writerWithdrawalTableStorePack.getTableAction()),
  loadNext: () => dispatch(writerWithdrawalTableStorePack.getTableNextAction()),
  // updateRow: ticketRow => dispatch(writerWithdrawalTableStorePack.updateTableAction(ticketRow)),
  // updateStatus: (withdrawalRow) => {
  //   dispatch(writerWithdrawalTableStorePack.updateTableAction(withdrawalRow))
  // },
  updateStatus: (writerWithdrawaRow) => {
    dispatch(writerWithdrawalTableStorePack.updateTableAction(writerWithdrawaRow))
  },
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  setSorting: (field, isAsc) => {
    dispatch(writerWithdrawalTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(writerWithdrawalTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(writerWithdrawalTableStorePack.resetTableFilterAction());
    dispatch(writerWithdrawalTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WriterWithdrawalPage);
